.top-navbar {
    border-bottom: 1px solid #E0CDA9;
    /* border-bottom: 1px solid #12a0aa; */
    padding: 0 7rem;
    background-color: #E0CDA9;
}

.navbar-items-color {
    color:  rgb(12, 70, 66);
}

.navbar-items-color:hover {
    color: black;
}

.profile-link:hover {
    background-color: unset;
}

.dropdown-item-hover:hover {
    text-decoration: underline !important;
    color: var(--bs-gray-1000) !important;
    background-color: unset;
}

.active-border {
    border-bottom: 2px solid #030e18;
}
